import deepMerge from 'deepmerge';
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeOptions,
  Theme
} from '@material-ui/core/styles';

const colors = {
  BG_COLOR: '#f3f3f3', // ufo grey,
  MAIN_COLOR: '#283D4C', // ufo dark blue
  MAIN_INVERTED_COLOR: '#ffffff', // white
  PRIMARY_COLOR: '#68a3c2', // ufo flash blue
  SECONDARY_COLOR: '#40667D', // UFO secondary
  DESCRIPTION_COLOR: '#9FB2BE',
  ATTENTION_COLOR: '#dc3545', // red
  SUCCESS_COLOR: '#40b844', // green
  WARNING_COLOR: '#ffc107', // orange

  // OTHER
  TRANSPARENT: 'transparent'
};

const makeTheme = (variant: ThemeOptions): Theme => {
  const common = {
    palette: {
      primary: { main: colors.MAIN_COLOR },
      secondary: { main: colors.PRIMARY_COLOR },
      error: { main: colors.ATTENTION_COLOR }
    }
  };

  const theme = createMuiTheme(deepMerge(common, variant));

  return responsiveFontSizes(theme);
};

const light: ThemeOptions = { palette: { type: 'light' } };

const dark: ThemeOptions = { palette: { type: 'dark' } };

const themes = {
  light: makeTheme(light),
  dark: makeTheme(dark)
};

export default themes;

/*

const myTheme = createMuiTheme({
  typography: { fontSize: 14 },
  palette: {
    type: 'light',
    success: { main: colors.SUCCESS_COLOR },
    error: { main: colors.ATTENTION_COLOR },
    warning: { main: colors.WARNING_COLOR },

    primary: { main: colors.MAIN_COLOR },
    secondary: { main: colors.PRIMARY_COLOR }
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: colors.ATTENTION_COLOR,
        '&$error': { colors: colors.ATTENTION_COLOR }
      }
    }
  }
})

*/

import { Container, createStyles, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface ISocial {
    icon: ReactNode;
    to: string;
}

interface IFooterProps {
    logo: ReactNode;
    social: ISocial[];
}

const useStyles = makeStyles(() =>
    createStyles({
        content: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 0',
            borderBottom: '10px solid #68a3c2'
        },
        logo: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around'
        },
        list: {
            display: 'flex',
            listStyleType: 'none'
        },
        item: {
            marginLeft: '20px',
            transition: '200ms opacity ease-in-out',
            '&:hover': { opacity: 0.5 }
        }
    })
);

export const Footer = ({ logo, social }: IFooterProps) => {
    const classes = useStyles();

    return (
        <Container>
            <div className={classes.content}>
                <a
                    href="https://ufodrive.com" target="_blank" rel="noopener noreferrer"
                    className={classes.logo}
                >
                    {logo}
                </a>

                <ul className={classes.list}>
                    {social.map((item) => (
                        <li key={item.to} className={classes.item}>
                            <a href={item.to} target="_blank" rel="noopener noreferrer">
                                {item.icon}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </Container>
    );
};

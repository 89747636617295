import React, { ReactNode } from 'react';
import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Link } from "gatsby-theme-material-ui";
import MediaLogo from '../../assets/svg/media-logo.svg';

interface HeaderProps {
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'center',
      padding: '30px 0',
    },
    logo: {

    },
    logoSvg: {
      display: 'block',
      height: '16px',
      width: 'auto'
    },
    navigation: {
      display: 'flex',
      marginLeft: 'auto'
    }
  })
)

export const Header = ({ children }: HeaderProps) => {
  const classes = useStyles()
  return (
    <Container>
      <div className={classes.content}>
        <Link to="/" className={classes.logo}>
          <MediaLogo className={classes.logoSvg} />
        </Link>

        <div className={classes.navigation}>{children}</div>
      </div>
    </Container>
  )
}

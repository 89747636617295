import React, { FC } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { IconButton, Menu, MenuItem, Theme } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import { Footer } from './Footer';
import themes from './theme';
import useSiteMetadata from '../../hooks/useSiteMetadata';
import { Header } from './Header';
import Logo from '../../assets/svg/ufodrive-logo.svg';
import Twitter from '../../assets/svg/twitter.svg';
import Instagram from '../../assets/svg/instagram.svg';
import Facebook from '../../assets/svg/facebook.svg';
import Linkedin from '../../assets/svg/linkedin.svg';
import MenuIcon from '@material-ui/icons/Menu';
import { HeaderLink } from './HeaderLink';

const query = graphql`
  {
    allVimeoShowcase {
      edges {
        node {
          fields {
            slug
          }
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default
  },
  menuButton: { marginRight: theme.spacing(2) },
  main: { backgroundColor: theme.palette.background.default }
}));

const LayoutComponent: FC = ({ children }) => {
  const classes = useStyles();
  const result = useStaticQuery(query);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { title } = useSiteMetadata();

  return (
    <div className={classes.root}>
      <Header>
        <IconButton
          edge="start" className={classes.menuButton} color="inherit"
          onClick={handleClick}
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="media-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <>
            {result.allVimeoShowcase.edges.map((f: any) => (
              <MenuItem key={f.node.fields.slug} onClick={handleClose}>
                <HeaderLink

                  name={f.node.name}
                  to={`/${f.node.fields.slug}`}
                />
              </MenuItem>
            ))}
          </>
        </Menu>
      </Header>
      <main className={classes.main}>{children}</main>
      <Footer
        logo={<Logo />}
        social={[
          { icon: <Twitter />, to: 'https://twitter.com/UFODriveTweets' },
          { icon: <Instagram />, to: 'https://www.instagram.com/ufodrive' },
          { icon: <Facebook />, to: 'https://www.facebook.com/UFODriveSocial' },
          {
            icon: <Linkedin />,
            to: 'https://www.linkedin.com/company/ufodrive'
          }
        ]}
      />
    </div>
  );
};

const Layout: FC = ({ children }) => {
  const theme: 'light' | 'dark' = 'light'; //useSelector((state: RootState) => state.app)

  return (
    <ThemeProvider theme={themes[theme]}>
      <LayoutComponent>{children}</LayoutComponent>
    </ThemeProvider>
  );
};

export default Layout;


import React, { ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';

interface IHeaderLinkProps {
  name: string;
  to: string;
  icon?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex',
      marginLeft: '40px',
      textDecoration: 'none',
      color: '#404040',
      transition: '200ms opacity ease-in-out',
      '&:firstOfType': { marginLeft: 0 },
      '&:hover': { opacity: 0.5 }
    },
    icon: {
      position: 'relative',
      marginRight: '8px',
      path: { fill: '#404040' }
    }
  }));

export const HeaderLink = ({ name, to, icon }: IHeaderLinkProps) => {
  const classes = useStyles();
  const isLink = typeof to !== 'undefined';
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || '');

  const content = () => (
    <>
      {icon && React.cloneElement(icon as any, { className: classes.icon })}
      {name}
    </>
  );

  if (isExternal) {
    return (
      <a
        className={classes.link} target="_blank" rel="noopener noreferrer"
        href={to}
      >
        {content()}
      </a>
    );
  }

  return (
    <Link className={classes.link} to={to}>
      {content()}
    </Link>
  );
};

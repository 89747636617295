

import { IconButton } from '@material-ui/core'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'

interface IProps {
    children: JSX.Element
}

const UFOSnackbarProvider = ({ children }: IProps) => {
    const notistackRef = React.createRef<SnackbarProvider>()

    const onClickDismiss = (key: SnackbarKey) => () => {
        notistackRef.current?.closeSnackbar(key)
    }

    return (
        <SnackbarProvider
            ref={notistackRef}
            action={(key: SnackbarKey) => (
                <IconButton onClick={onClickDismiss(key)} ><CloseIcon /></IconButton>
            )} maxSnack={3}
        >
            {children}
        </SnackbarProvider>
    )
}

export default UFOSnackbarProvider
